import { overlay } from '@laufire/utils/collection';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react'; // TODO: Faizan yeh kya hai, React sai jo functions use horey hai unko destructure kar kai use karo, dono convetion ek sath kyu ?

import { DEVICE_SURFACE, ROLE } from 'config/common';
import { getUserDetails } from 'helpers/getUserDetails';
import { useIsMobile } from 'helpers/screenResolutionHelper';
import {
  addItemToCart,
  addItemToDefaultCart,
  authenticateCart,
  getCartDetails,
  getSelectedCart,
  getUserEventCarts,
  setSelectedCart
} from 'services/cart.service';
import { OPS_PORTAL_URL } from 'services/connections.service';
import * as GuestUserService from 'services/guestUser.service';
import {
  getAgentAuth,
  getAgentInfo,
  getAuth,
  getUserAuth,
  removeClaims,
  removeUserAuth,
  setUserAuth
} from 'services/identity.service';
import {
  getSelectedUserEvent,
  setSelectedUserEvent
} from 'services/myEvents.service';
import { getProductById } from 'services/product.service';
import { DELETE_CLAIMS_URL } from 'services/url.service';
import { updateUserTimeZone } from 'services/user.service';

export const UIContext = React.createContext();

export const useUIContext = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUIContext must be used within a UIProvider');
  }
  return context;
};

const defaultCartParameter = {
  cartItems: [],
  listedPriceAmount: 0,
  orderTotal: 0,
  deliveryCharge: 0,
  discountAmount: 0,
  listedDiscountAmount: 0,
  promoCodeDiscountAmount: 0,
  vatAmount: 0,
  promoCodeDetails: null,
  activeDefaultCart: null,
  productPartners: {},
  externalNotes: null,
  internalNotes: null,
  // #TODO: Remove the following superseded value.
  areAllProductsAvailable: true,
  cartOrderScreenType: 'cart'
};
const defaultKidsQuestionnaireState = {
  'The Birthday kid is ?': { id: 'All', name: 'All' },
  'How old is the kid ?': { id: 'All', name: 'All' },
  'Have a budget in mind ?': { id: 'All', name: 'All' }
};

const { HOST, PLANNER } = ROLE;
const { MOBILE, WEB } = DEVICE_SURFACE;

export const UIProvider = (props) => {
  const [cartList, setCartList] = useState([]);
  const [defaultHostCartDetail, setDefaultHostCartDetail] = useState(null);

  const [defaultCart, setDefaultCart] = useState({});
  const [cart, setCart] = useState(defaultCartParameter);

  const [kidsQuestionnaire, setKidsQuestionnaire] = useState(
    defaultKidsQuestionnaireState
  );
  const [isLoading, setIsLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [productPaginationEndIndex, setProductPaginationEndIndex] = useState(0);
  const [productCategoryUrl, setProductCategoryUrl] = useState('');
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const Router = useRouter();
  const [isMobile] = useIsMobile();
  // #TODO: Client side functions should be called separately, for clarity.
  const [agentInfo, setAgentInfo] = useState({});
  const role = agentInfo.agent ? PLANNER : HOST;
  const surface = isMobile ? MOBILE : WEB;
  const [authUser, setAuthUser] = useState({});
  const [showQuoteForm, setShowQuoteForm] = useState(false);
  const [showQuoteRequestSubmittedAlert, setShowQuoteRequestSubmittedAlert] =
    useState(false);

  const createGuestUser = async () => {
    const guestUser = await GuestUserService.createGuestUser();
    if (guestUser.status) {
      setUserAuth({
        id: guestUser?.entity?.id,
        isGuest: true
      });
      setSelectedUserEvent({ data: null });
    }
  };

  // TODO: planner-cart-v1 - Faizan to explain to sidd why we need IIFE, and a convoluted function
  const updateCartInPortalHeader = (() => {
    const store = { ...cart };

    return (patch) => {
      overlay(store, patch);
      setCart({
        ...store,
        cartItemCount: store.cartItems?.length ?? 0
      });
    };
  })();

  const getCartDetailsToUpdate = (cartDetails) => ({
    ...cartDetails,
    promoCodeDetails: cartDetails.derivedValues?.promoCode,
    promoCodeDiscountAmount: cartDetails.derivedValues?.promoCodeDiscountAmount,
    promoCodeValidationInfo: cartDetails.derivedValues?.promoCodeValidationInfo
  });

  const resetSelectedCart = () => {
    setSelectedCart({ data: null });
  };

  const getUserEventCartList = async () => {
    const { id: userCartId } = getAuth();
    const { entity: userEventCarts = [] } = await getUserEventCarts(
      userCartId,
      getSelectedUserEvent()
    );
    userEventCarts.length > 0 && setCartList(userEventCarts);
    return userEventCarts;
  };

  const getAvailableCartDetail = async () => {
    const { id: userCartId } = getAuth();
    const userEventCartList = await getUserEventCartList();
    if (userEventCartList.length > 0) {
      const availableDefaultCart = userEventCartList.find(
        ({ isDefault }) => isDefault
      );

      if (getSelectedCart()?.id || availableDefaultCart?.id) {
        const { entity: localCart } = await getCartDetails({
          userCartId,
          cartId: getSelectedCart()?.id ?? availableDefaultCart.id
        });
        if (localCart) {
          setDefaultCart(userEventCartList, availableDefaultCart);
          setDefaultHostCartDetail(localCart);
          updateCartInPortalHeader(
            getCartDetailsToUpdate({
              ...localCart,
              userCartId
            })
          );
        }
      }
      setIsLoading(false);
    }
  };

  const checkAuth = async () => {
    let auth = getAuth();
    if (!auth) {
      auth = await createGuestUser();
    }
    getAvailableCartDetail();
  };
  const getAuthenticatedUserDetail = () => {
    const auth = getAuth();
    return getUserDetails(auth?.id) || {};
  };

  const getAndSetAuthUser = async () => {
    const customerInfo = await getAuthenticatedUserDetail();
    if (customerInfo) {
      setAuthUser(customerInfo);
    }
  };
  const updateSelectedCart = (patch) => {
    setSelectedCart({
      data: patch
    });
  };

  useEffect(() => {
    getAndSetAuthUser();
  }, []);

  useEffect(() => {
    getSelectedUserEvent() && getUserEventCartList();
  }, [getSelectedUserEvent()]);

  useEffect(() => {
    checkAuth();
    setAgentInfo(getAgentInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createCart = async ({ product, quantity, revertQuantityToMin }) => {
    const userCartId = getAuth().id;
    const addToCartApi = getSelectedCart()?.id
      ? addItemToCart
      : addItemToDefaultCart;
    const res = await addToCartApi(
      userCartId,
      {
        userCartId,
        entityId: product.id,
        entityType: product.type,
        quantity,
        priceId: product.price.id
      },
      getSelectedCart()?.id
    );
    if (res.status) {
      revertQuantityToMin();
      getAvailableCartDetail();
    }
    return res.entity;
  };

  useEffect(() => {
    // TODO: Rajesh to include cartId to make it more usable for third party
    window.addToCart = async (productId, quantity) => {
      const userCartId = getAuth().id;
      const { entity: product } = await getProductById(productId);
      const addToCartApi = getSelectedCart()?.id
        ? addItemToCart
        : addItemToDefaultCart;
      const res = await addToCartApi(
        userCartId,
        {
          userCartId,
          entityId: productId,
          entityType: product.type,
          quantity,
          priceId: product.price.id
        },
        getSelectedCart()?.id
      );
      if (res.status) {
        getAvailableCartDetail();
      }
    };
  }, []);

  const navigateUser = (pathName) => {
    if (pathName) {
      return Router.push(pathName);
    }
    return Router.push('/');
  };

  const updateAndSetUserTimeZone = async () => {
    const userAuth = getUserAuth() || {};

    const updatedUserTimeZone = await updateUserTimeZone(userAuth.id, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    if (updatedUserTimeZone.status) {
      setUserAuth({
        ...userAuth,
        timeZone: updatedUserTimeZone.entity.timeZone
      });
    }
  };

  const logIn = async (userDetails, pathName, isCheckout) => {
    const planner = getAgentAuth();
    const silentlyRedirectIfHostPortalOTPLoginIsAttemptedByAgent =
      planner?.token;
    if (silentlyRedirectIfHostPortalOTPLoginIsAttemptedByAgent) {
      Router.replace(OPS_PORTAL_URL);
    }

    const guestUser = getUserAuth();
    const response = await authenticateCart(userDetails.id, guestUser?.id);
    if (response.status) {
      setUserAuth(userDetails);
      updateAndSetUserTimeZone();
      resetSelectedCart();
      getAvailableCartDetail();
      getAndSetAuthUser();
      !isCheckout && navigateUser(pathName);
      return true;
    }

    Router.push(`/login?redirectTo=/${pathName}`); // TODO: Faizan: static path is used instead of config, redirectTo should be present only if pathName was present

    return false;
  };

  const hostLogOut = () => {
    removeUserAuth();
    resetSelectedCart();
    setSelectedUserEvent({ data: null });

    return Router.push({
      pathname: '/login'
    });
  };

  const crmLogOut = () => {
    removeUserAuth();
    removeClaims();
    resetSelectedCart();
    setSelectedUserEvent({ data: null });

    return Router.replace(DELETE_CLAIMS_URL);
  };

  const logOutHandlers = {
    [ROLE.PLANNER]: crmLogOut,
    [ROLE.HOST]: hostLogOut
  };

  const logOut = logOutHandlers[role];

  const updateKidsQuestionnaire = (newQuestionnaire) => {
    setKidsQuestionnaire((existingQuestionnaire) => ({
      ...existingQuestionnaire,
      ...newQuestionnaire
    }));
  };

  const updateScrollPosition = (scrollValue) => {
    setScrollPosition(scrollValue);
  };

  const updateProductPaginationEndIndex = (end) => {
    setProductPaginationEndIndex(end);
  };

  const updateCategoryUrl = (url) => {
    setProductCategoryUrl(url);
  };

  const updateTotalProductsCount = (count) => {
    setTotalProductsCount(count);
  };

  const value = {
    ...cart,
    cartList,
    getUserEventCartList,
    createCart,
    logIn,
    logOut,
    updateAndSetUserTimeZone,
    updateKidsQuestionnaire,
    kidsQuestionnaire,
    isLoading,
    updateScrollPosition,
    scrollPosition,
    updateProductPaginationEndIndex,
    productPaginationEndIndex,
    updateCategoryUrl,
    productCategoryUrl,
    updateTotalProductsCount,
    totalProductsCount,
    agentInfo,
    role,
    surface,
    userCartId: agentInfo?.id,
    updateCartInPortalHeader,
    updateSelectedCart,
    selectedCart: getSelectedCart(),
    defaultCart,
    resetSelectedCart,
    authUser,
    showQuoteForm,
    setShowQuoteForm,
    showQuoteRequestSubmittedAlert,
    setShowQuoteRequestSubmittedAlert,
    defaultHostCartDetail,
    setDefaultHostCartDetail
  };

  return (
    <UIContext.Provider
      value={value}
      {...props}
    />
  );
};

export const ManagedUIContext = ({ children }) => (
  <UIProvider>{children}</UIProvider>
);
