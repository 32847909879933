// TODO: Faizan & sidd to attempt to clean up this file - after removing planner portal code from host portal

import _ from 'lodash';

import {
  COOKIE_MAP,
  getCookie,
  removeCookie,
  setCookie
} from 'services/cookie.service';

export const getAgentInfo = () => {
  // TODO: TJ, Sidd & Rj to re-visit while fixing host, partner, planner roles and access control
  const agent = getCookie({ name: COOKIE_MAP.OPS_PORTAL_USER_IDENTITY });
  const { userId: id } =
    getCookie({ name: COOKIE_MAP.CLAIM_AS_HOST_BY_PLANNER }) || {};
  return agent?.id && id ? { id, agent } : {};
};

const getAuthOfAgentWithAssumedRole = () => {
  const { id, agent } = getAgentInfo();
  return (
    id &&
    agent && {
      id,
      isGuest: false,
      agent,
      agentToken: agent.token
    }
  );
};

// TODO: alter the function names below to indicate that data contain identity and auth token

export const getAgentAuth = () =>
  getCookie({ name: COOKIE_MAP.OPS_PORTAL_USER_IDENTITY });

export const getUserAuth = () =>
  getCookie({ name: COOKIE_MAP.HOST_PORTAL_USER_IDENTITY });

export const setUserAuth = (authObject) =>
  setCookie({ name: COOKIE_MAP.HOST_PORTAL_USER_IDENTITY, data: authObject });

export const removeUserAuth = () =>
  removeCookie({ name: COOKIE_MAP.HOST_PORTAL_USER_IDENTITY });

// TODO: Tanmay/Rajesh this is in-apt, it is used at many places where polymorphism is misleading, example userCartId can never be of planner
export const getAuth = () => getAuthOfAgentWithAssumedRole() || getUserAuth();

export const getAuthToken = () => {
  const { token, agentToken } = getAuth() || {};
  return token || agentToken;
};

export const isAuthenticated = () => {
  const auth = getAuth();
  return Boolean(!_.isEmpty(auth) && !auth.isGuest); // TODO: sidd here we should check token as well, instead of checking just isGuest
};

export const removeClaims = () =>
  removeCookie({ name: COOKIE_MAP.CLAIM_AS_HOST_BY_PLANNER });

export const isPlannerLoggedIn = Boolean(getAgentAuth()?.id);
